<template lang="pug">
  el-table(
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='smartCards'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='status'
      :label="$t('common.status')"

    )
      template(slot-scope='scope')
        .status-wrapper.d-flex.align-items-center(v-if="scope.row.status == 'order_initiated'")
          span.oval.cancelled
          span {{ handleStatus(scope.row.status) }}
        .status-wrapper.d-flex.align-items-center(v-if="scope.row.status == 'order_received'")
          span.oval.pending
          span {{ handleStatus(scope.row.status) }}
        .status-wrapper.d-flex.align-items-center(v-if="scope.row.status == 'order_completed'")
          span.oval.completed
          span {{ handleStatus(scope.row.status) }}
    el-table-column(
      prop='created_at'
      :label="$t('common.time_of_report')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='user'
      :label="$t('common.customer_name')"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer(
          v-if="scope.row.user"
          @click="goToWIthId('customers', scope.row.user.id)"
        ) {{ scope.row.user.first_name + " " + scope.row.user.last_name }}
        span(v-else) -
    el-table-column(
      prop='user'
      :label="$t('common.customer_contact')"
    )
      template(slot-scope='scope')
        span {{ $t('common.email_address') + ': ' }}
        span( v-if="scope.row.email") {{ scope.row.email }} 
        span(v-else) -
        br
        span {{ $t('common.phone_number') + ': '}}
        span( v-if="scope.row.phone_number") {{ scope.row.phone_number }}
        span(v-else) -
    el-table-column(
      prop='user'
      :label="$t('common.full_address')"
    )
      template(slot-scope='scope')
        span( v-if="scope.row.user") 
          span {{ scope.row.address_first_line + ', ' + scope.row.address_second_line }}
          br
          span {{ scope.row.city + ', ' + scope.row.county + ' ' + scope.row.postal_code }}
          br 
          span {{ scope.row.country }}
        span(v-else) -
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
      width="360px"
    )
      template(slot-scope='scope')
        .d-flex(:key="actionsButtonKey")
          el-button.custom-table-btn.mr-2(
            v-if="scope.row.status != 'order_completed' && scope.row.status != 'order_received'"
            v-loading="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn')"
            :disabled="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn') || isActionButtonsLoading(scope.row, 'loadingSolveSmartCardBtn')"
            @click="startWork(scope.row)"
          ) {{ $t('smart_cards.order_received') }}
          el-button.custom-table-btn-dark.mr-2(
            v-if="scope.row.status != 'order_completed' && scope.row.status != 'order_initiated'"
            v-loading="isActionButtonsLoading(scope.row, 'loadingSolveSmartCardBtn')"
            :disabled="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn') || isActionButtonsLoading(scope.row, 'loadingSolveSmartCardBtn')"
            @click="solveSmartCard(scope.row)"
          ) {{ $t('smart_cards.order_completed') }}
          el-button.custom-table-btn-dark.mr-2(
            v-if="scope.row.status == 'order_initiated'"
            @click="handleDeleteSmartCardDialog(scope.row)"
          ) {{ $t('smart_cards.delete_order') }}
</template>

<script>
import { filterUtils, inBetween, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { mapActions, mapMutations, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "SmartCardsViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    smartCards: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  watch: {
    smartCards() {
      this.smartCards.forEach((smartCard, index) => {
        this.loadingStartWorkBtn[index] = {
          isLoading: false,
          id: smartCard.id
        }

        this.loadingSolveSmartCardBtn[index] = {
          isLoading: false,
          id: smartCard.id
        }
      })
    },
    allowDeletion() {
      this.handleDeleteSmartCard();
    },
  },

  data() {
    return {
      actionsButtonKey: 0,
      loadingStartWorkBtn: [],
      loadingSolveSmartCardBtn: [],

      MAX_FIELD_LENGTH,
      inBetween,
      trimTableField,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),

    ...mapState("passwordValidationDelete", [
      "dataToDelete",
      "allowDeletion",
      "passwordConfirmation",
    ]),
  },

  methods: {
    ...mapActions("smartCard", ['updateSmartCardStatus', 'deleteSmartCard']),

    ...mapMutations("dialog", [
      "showViewPasswordValidationDeleteDialog",
      "hideViewPasswordValidationDeleteDialog"
    ]),
    ...mapMutations("smartCard", ["setSmartCard", 'setShouldFetchSmartCards']),

    ...mapMutations("passwordValidationDelete", [
      "setDialogData",
      "setLoading",
      "setDataToDelete",
      "setAllowDeletion",
    ]),

    isActionButtonsLoading(smartCard, btnType) {
      let indexOfBtn = this._data[btnType].findIndex(btn => btn.id === smartCard.id)
      return this._data[btnType][indexOfBtn].isLoading
    },

    startWork(smartCard) {
      let indexOfBtn = this.loadingStartWorkBtn.findIndex(btn => btn.id === smartCard.id)
      this.loadingStartWorkBtn[indexOfBtn].isLoading = true
      this.actionsButtonKey += 1

      let params = { ...smartCard, status: 'order_received' }
      this.updateSmartCardStatus(params)
          .then(() => {
            this.setShouldFetchSmartCards(true)
          })
          .finally(() => {
            this.loadingStartWorkBtn[indexOfBtn].isLoading = false
            this.actionsButtonKey += 1
          })
    },

    solveSmartCard(smartCard) {
      let indexOfBtn = this.loadingSolveSmartCardBtn.findIndex(btn => btn.id === smartCard.id)
      this.loadingSolveSmartCardBtn[indexOfBtn].isLoading = true
      this.actionsButtonKey += 1

      let params = { ...smartCard, status: 'order_completed' }
      this.updateSmartCardStatus(params)
          .then(() => {
            this.setShouldFetchSmartCards(true)
          })
          .finally(() => {
            this.loadingSolveSmartCardBtn[indexOfBtn].isLoading = false
            this.actionsButtonKey += 1
          })
    },

    handleDeleteSmartCard(){
      if (this.allowDeletion) {
        let payload = {
          computedQueriesUrl: this.dataToDelete.id,
          password_confirmation: this.passwordConfirmation,
        };

        this.deleteSmartCard(payload)
          .then(() => {
            this.hideViewPasswordValidationDeleteDialog();
          })
          .catch((error) => {
            this.setAllowDeletion(false);
          })
          .finally(() => {
            this.setLoading(false);
          });
      }
    },

    handleDeleteSmartCardDialog(smartCard) {
      this.showViewPasswordValidationDeleteDialog();
      this.setDialogData({
        dialogTitle: `${this.$t("smart_cards.validations.delete_title")}`,
        dialogMessage: this.$t("smart_cards.validations.delete_warning"),
      });

      this.setAllowDeletion(false);
      this.setDataToDelete(smartCard);
    },

    handleStatus(status){
      if(status == 'order_initiated')
        return 'Cerere inițiată';
      if(status == 'order_received')
        return 'Cerere înregistrată';
      if(status == 'order_completed')
        return 'Cerere închisă';
    },
  }
}
</script>
