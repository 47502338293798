<template lang="pug">
  el-pagination.text-center.pt-3(
    layout="prev, pager, next"
    :current-page.sync="currentPage"
    :total="totalCount"
    :page-sizes="[10, 50, 100]"
    :page-size="pageSize"
    v-if="totalCount > 1"
    @current-change="handleGetData"
    @size-change="handleChangePageSize"
  )
      //- :page-size.sync="pageItems"
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DialogTablePagination",

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState("bike", ["bike"]),
  },

  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      pageSize: 10,
      pageItems: 1,
      handleGetDataLoading: false,
    };
  },

  mounted() {
    this.pageSize = Number(localStorage.getItem('pageSize')) || 10
    this.handleGetData();
  },

  watch: {
    activeName() {
      if (this.activeName === "maintenance_history" || this.activeName === 'bike_history') {
        this.handleGetData();
      }
    },

    bike() {
      this.handleGetData();
    },

    handleGetDataLoading() {
      this.$emit("handleTableLoading", this.handleGetDataLoading);
    },
  },

  methods: {
    ...mapActions("bike", ["getBikeMaintenanceHistory", "getBikeRidesHistory"]),

    computedParams() {
      let computedParams = `?page=${this.currentPage}&page_size=${this.pageSize}`;
      return { id: this.bike.id, params: computedParams };
    },

    async handleGetData() {
      this.handleGetDataLoading = true;
      let computedParams = this.computedParams();
      const response = await this.computeGetAction(computedParams)
      this.emitDataToView(response);
      this.handleGetDataLoading = false;
    },

    async computeGetAction(computedParams) {
      if (this.activeName === 'maintenance_history') {
        return await this.getBikeMaintenanceHistory(computedParams);
      } else if (this.activeName === 'bike_history') {
        return await this.getBikeRidesHistory(computedParams);
      }
    },

    emitDataToView(response) {
      this.totalCount = Number(response.headers["total-count"]);
      // this.pageItems = Number(response.headers["page-items"]);
      this.$emit("viewData", response.data);
    },

    async handleChangePageSize(newPageSize) {
      localStorage.setItem('pageSize', newPageSize)

      this.pageSize = newPageSize;
      await this.handleGetData()
    }
  },
};
</script>
