<template lang="pug">
  .view-docking-station
    .docking-station-table
      el-row(:gutter="50" style="margin: auto; text-align:center;")
        el-tabs.mt-4(
          v-model='activeName'
        )
          el-tab-pane(
            :label="$t('docking_stations.tab.bays_details')"
            name='bays_details'
          )
          el-tab-pane(
            :label="$t('docking_stations.tab.statistics')"
            name='statistics'
          )
          el-tab-pane(
            v-if="hideSubmenu()"
            :label="$t('docking_stations.tab.status_history')"
            name='status_history'
          )
          el-tab-pane(
            v-if="hideSubmenu()"
            :label="$t('docking_stations.tab.watchdog_details')"
            name='watchdog_details'
          )
      BaysDetails(
        v-if="activeName === 'bays_details'" 
        :activeName="activeName"
        :bays="dockingStation.bays"
        :loading="loading")
      RidesStatistics(
        v-if="activeName === 'statistics'" 
        :activeName="activeName"
        :loading="loading")
      StatusHistory(
        v-if="hideSubmenu() && activeName === 'status_history'" 
        :activeName="activeName"
        :loading="loading")
      WatchdogsDetails(
        v-if="hideSubmenu() && activeName === 'watchdog_details'" 
        :activeName="activeName"
        :watchdog_messages="watchdog_messages"
        :loading="loading")
</template>

<script>
import BaysDetails from "@components/docking_stations/viewDockingStation/BaysDetails";
import RidesStatistics from "@components/docking_stations/viewDockingStation/RidesStatistics";
import StatusHistory from "@components/docking_stations/viewDockingStation/StatusHistory";
import WatchdogsDetails from "@components/docking_stations/viewDockingStation/WatchdogsDetails";
import { mapActions, mapMutations, mapState } from "vuex";
import { filterUtils } from "@utils/filterUtils";
import { roles } from "@utils/roles";
import { routerUtils } from "@utils/router";
import store from "@state/store";

export default {
  name: "ViewDockingStation",
  mixins: [routerUtils, filterUtils],
  components: { 
    BaysDetails,
    RidesStatistics,
    StatusHistory,
    WatchdogsDetails,
    },

  data() {
    return {
      activeName: "bays_details",
      bays: [],
      watchdog_modules: [],
      watchdog_messages: [],
      loading: false,
      seeQrCode: false
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState('dockingStation', ['dockingStation']),
  },

  async created() {
    this.loading = true;
    this.watchdog_messages = await this.getWatchdogMessages(this.dockingStation.id);
    this.loading = false;
  },

  methods: {
    ...mapActions('dockingStation', ['getDock', 'getWatchdogMessages']),

    hideSubmenu() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.view-docking-station-details {
  h4 {
    color: white;
    font-family: Montserat, sans-serif;
    font-weight: 600;
    letter-spacing: 0.12px;
    line-height: 19px;
  }
}

p {
  display: inline;
}
</style>
