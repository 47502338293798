<template lang="pug">
  .view-bike-rides
    .view-bike-statistics
      el-row.center.details-bold(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 15px;"
      )
        el-col(:span="3")
          span {{ $t('bikes.statistics.total_rides') }}

        el-col(:span="5")
          span {{ $t('bikes.statistics.completed_rides') }}

        el-col(:span="6")
          span {{ $t('bikes.statistics.canceled_by_admin') }}

        el-col(:span="6")
          span {{ $t('bikes.statistics.rides_with_hardware_error') }}

        el-col(:span="5")
          span {{ $t('bikes.statistics.rides_with_customer_errors') }}
      el-row.center(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 10px; margin-bottom: 25px; font-size:20px"
      )
        el-col(:span="3")
          span.break-word {{ statistics.no_of_total_rides }}

        el-col(:span="5")
          span.break-word {{ statistics.no_of_completed_rides }}

        el-col(:span="6")
          span.break-word {{ statistics.no_of_rides_canceled_by_admin }}

        el-col(:span="6")
          span.break-word {{ statistics.no_of_failed_unlocking_bike_because_of_hardware_error_rides }}
        el-col(:span="5")
          span.break-word {{ statistics.no_of_rides_with_customers_errors }}
    .bike-history
      el-table.bike_history_table(
        :data="bike_history"
        v-loading="loading"
        :gutter="0"
        height="250"
        style="width: 100%;"
      )
        el-table-column(
          width="55"
        )
          template(slot-scope='scope')
            span.pre-line {{ scope.$index + 1}}
        el-table-column(
          width="180"
          prop="created_at"
          :label="$t('label.date')"
        )
          template(slot-scope='scope')
            span {{ computeDateFormat(scope.row.created_at) }}
        el-table-column(
          prop="customer"
          :label="$t('common.last_customer')"
        )
          template(slot-scope='scope')
            span.text-underline.cursor-pointer( 
              v-if="scope.row.customer"
              @click="openInNewTab(scope.row.customer.id)"
              ) {{ scope.row.customer.first_name + " " + scope.row.customer.last_name }}
            span(v-else) -
        //- el-table-column(
        //-   width="100"
        //-   prop="id"
        //-   :label="$t('label.last_ride_id')"
        //- )
        //-   template(slot-scope='scope')
        //-     span(@click="scope.row.id") {{ scope.row.id }}
        el-table-column.pre-line(
          width="195"
          prop='start_docking_station'
          :label="$t('label.start_dock_end_dock')"
        )
          template(slot-scope='scope')
            .docks.pre-line
              span(v-if="scope.row.start_docking_station_name") {{ scope.row.start_docking_station_name + "/ \n" }}
              span(v-if="!scope.row.start_docking_station_name") -/

              span {{ scope.row.end_docking_station_name }}
              span(v-if="!scope.row.end_docking_station_name") -
        el-table-column(
          prop="status"
          :label="$t('label.last_ride_status')"
        )
          template(slot-scope='scope')
            .status-wrapper(
              v-if="inBetween('ridesStatuses', scope.row.status, 21, 40)"
            )
              .status
                span.oval.completed
                //- span {{ ridesStatuses[scope.row.status].message }}
                span {{ scope.row.status_object.label }}
            .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 41, 60)")
              .status
                span.oval.cancelled
                span {{ scope.row.status_object.label }}
            .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 1, 20)")
              .status
                span.oval.pending
                span {{ scope.row.status_object.label }}
      DialogTablePagination(
        :activeName="activeName"
        @viewData="handleGetData"
        @handleTableLoading="handleLoading"
      )
</template>

<script>
import DialogTablePagination from "@components/_shared/DialogTablePagination";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { ridesStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "BikeHistory",
  components: { DialogTablePagination },
  mixins: [filterUtils, routerUtils],

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      bike_history: [],
      statistics: {
        no_of_total_rides: 0,
        no_of_completed_rides: 0,
        no_of_rides_canceled_by_admin: 0,
        no_of_failed_unlocking_bike_because_of_hardware_error_rides: 0,
        no_of_rides_with_customers_errors: 0,
      },

      loading: false,

      inBetween,
      ridesStatuses,
    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
  },

  async created() {
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapMutations("dialog", [
      "hideViewPasswordValidationDeleteDialog",
      "hideViewBikeDetailsDialog"
    ]),

    ...mapActions("bike", ["getBikeStatistics"]),

    handleGetData(data) {
      this.bike_history = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },

    openInNewTab(id) {
      let route = this.$router.resolve({path: `customers/${id}`});
      window.open(route.href, '_blank');
    },

    async handleGetCustomersStatistics() {
      this.statistics = await this.getBikeStatistics(this.bike.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
