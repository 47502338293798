<template lang="pug">
  .modal-bike-details
    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
    )
      el-col(:span="7")
        span {{ $t('common.serial_number') }}

      el-col(:span="7")
        span {{  $t('label.rfid') }}

      el-col(:span="7")
        span {{  $t('common.state_of_charge') }}

      el-col(:span="7")
        span {{ $t('common.tracker') }}

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="7")
        span.break-word {{ bike.serial_number}}

      el-col(:span="7")
        span.break-word {{ bike.rfid}}

      el-col(:span="7")
        span.break-word {{ $t(`${handleBikeStateOfCharge(bike.state_of_charge)}`) }}

      el-col(:span="7")
        span.break-word {{ (bike.tracker && bike.tracker.name) || '-'}}

    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 20px"
    )
      el-col(:span="7")
        span {{ $t('common.cost_center_name') }}

      el-col(:span="7")
        span {{ $t('common.docking_station') }}-{{ $t('common.bay') }}

      el-col(:span="7")
        span {{  $t('common.bike_type') }}

      el-col(:span="7")
        span {{ $t('trackers.serial_number') }}

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="7")
        span.break-word {{ (bike.cost_center && bike.cost_center.name) || '-' }}

      el-col(:span="7")
        span.break-word {{ (bike.docking_station && bike.docking_station.name) || '-' }}-{{ (bike.bay && bike.bay.number) || '-'}}

      el-col(:span="7")
        span.break-word {{ bike.bike_type || '-' }}

      el-col(:span="7")
        span(v-if="bike.tracker")
          span(v-if="bike.tracker.mac" :title="$t('common.mac')") {{ bike.tracker.mac }}
          br
          span(v-if="bike.tracker.imei" :title="$t('common.imei')") {{ bike.tracker.imei }}
          span(v-if="bike.tracker.dev_eui" :title="$t('common.dev_eui')") {{ bike.tracker.dev_eui }}
        span(v-else) -
    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 20px"
    )

    el-row.center(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )

    el-row.center.details-bold(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span {{ $t('trackers.latitude') }} {{ $t('trackers.longitude') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_report') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_create') }}

    el-row.center(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span(v-if='bike.tracker.tracker_latest_info.location').break-word {{ bike.tracker.tracker_latest_info.location.latitude  || '-' }}, {{ bike.tracker.tracker_latest_info.location.longitude  || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.location') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.location.reported_at) || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.location') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.location.added_at) || '-' }}
        span(v-else) -
    el-row.center.details-bold(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 20px"
    )
      el-col(:span="14") 
        span {{ $t('trackers.v_in') }}/ {{ $t('trackers.v_bat') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_report') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_create') }}

    el-row.center(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span(v-if='bike.tracker.tracker_latest_info.power') {{ bike.tracker.tracker_latest_info.power.v_in /1000.0 + 'V' || '-' }}/ {{ bike.tracker.tracker_latest_info.power.v_bat /1000.0 + 'V' || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.power') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.power.reported_at) || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.power') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.power.added_at) || '-' }}
        span(v-else) -
    el-row.center.details-bold(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span {{ $t('trackers.fw_version') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_report') }}

      el-col(:span="7")
        span {{  $t('trackers.time_of_create') }}

    el-row.center(
      v-if='bike.tracker && bike.tracker.tracker_latest_info'
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 10px"
    )
      el-col(:span="14")
        span(v-if='bike.tracker.tracker_latest_info.about') {{ computeFWVerionDateFormat(bike.tracker.tracker_latest_info.about.fw_ver)  || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.about') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.about.reported_at) || '-' }}
        span(v-else) -
      el-col(:span="7")
        span(v-if='bike.tracker.tracker_latest_info.about') {{ computeTrackerDateFormat(bike.tracker.tracker_latest_info.about.added_at) || '-' }}
        span(v-else) -
    el-row(
      :gutter="50"
      center
      style="margin-top: 32px; margin-bottom: 16px; text-align:center;"
    )
      el-col
        button.begin-maintenance-btn.cursor-pointer(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['start_maintenance'])"
          :class="(bike.status === 'in_ride' || bike.status === 'available_in_station') ? 'gray-btn' : 'dark-blue-btn'"
          :disabled="bike.status === 'in_ride' || bike.status === 'available_in_station'"
          @click="handleStartMaintenance(bike)"
        )
          div(v-if="bike.status !== 'under_maintenance'") {{ $t('dialogs.bikes.begin_maintenance') }}
          div(v-if="bike.status === 'under_maintenance'") {{ $t('dialogs.bikes.continue_maintenance') }}

    BikeMap()
</template>

<script>
import { bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { mapActions, mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import BikeMap from "@components/bikes/viewBikeDetails/BikeMap"

export default {
  name: "Details",
  mixins: [filterUtils, general],

  components: { BikeMap },

  data() {
    return {
      bikesStatuses,
      inBetween,
      dynamicViewsConstants,
      trackerData: {
        latitude: null,
        longitude: null,
        reported_at: null,
        created_at: null
      }

    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("bike", ["startMaintenance"]),

    ...mapMutations("dialog", [
      "showViewRideDetailsDialog",
      "showViewBikeMaintenanceDialog",
      "hideViewBikeDetailsDialog",
    ]),

    ...mapMutations("ride", ["setRide"]),

    ...mapMutations("bike", ["setBike", "setShouldFetchBikes"]),

    goToCustomer(id) {
      // TODO: this will be done in future branches
    },

    async goToRide(id) {
      // TODO: this will be done in future branches
    },

    handleBikeStateOfCharge(state_of_charge){
      if (state_of_charge == 1) return 'bikes.state_of_charge.fully_charged'
      if (state_of_charge == 2) return 'bikes.state_of_charge.medium_charged'
      if (state_of_charge == 3) return 'bikes.state_of_charge.low_battery'
      return 'bikes.state_of_charge.undefined'
    },

    handleStartMaintenance(bike) {
      if (bike.status === "under_maintenance") {
        this.showViewBikeMaintenanceDialog();
        this.setBike(bike);
        this.hideViewBikeDetailsDialog();
      } else {
        this.startMaintenance(bike.id).then(() => {
          this.setBike(bike);
          this.showViewBikeMaintenanceDialog();
          this.hideViewBikeDetailsDialog();
          this.setShouldFetchBikes(true);
        });
      }
    },
  },
};
</script>
