<template lang="pug">
  .view-bike-statistics
    el-row.center.details-bold(
      :gutter="50"
      type="flex"
      justify="center"
      style="margin-top: 15px;"
    )
      el-col(:span="7")
        span {{ $t('bikes.statistics.total_rides') }}

      el-col(:span="7")
        span {{ $t('bikes.statistics.completed_rides') }}

      el-col(:span="7")
        span {{ $t('bikes.statistics.rides_with_hardware_error') }}

      el-col(:span="7")
        span {{ $t('bikes.statistics.rides_with_other_errors') }}

    el-row.center(
      :gutter="50"
      type="flex"
      style="margin-top: 10px; margin-bottom: 25px"
    )
      el-col(:span="7")
        span.break-word {{ statistics.no_of_total_rides }}

      el-col(:span="7")
        span.break-word {{ statistics.no_of_completed_rides }}

      el-col(:span="7")
        span.break-word {{ statistics.no_of_failed_unlocking_bike_because_of_hardware_error_rides }}

      el-col(:span="7")
        span.break-word {{ statistics.no_of_maintenances }}

</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "BikeStatistics",

  data() {
    return {
      statistics: {
        no_of_total_rides: 0,
        no_of_completed_rides: 0,
        no_of_failed_unlocking_bike_because_of_hardware_error_rides: 0,
        no_of_maintenances: 0
      },
      
    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
    ...mapState("auth", ["user"]),
  },

  async created() {
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapActions("bike", ["getBikeStatistics"]),

    async handleGetCustomersStatistics() {
      this.statistics = await this.getBikeStatistics(this.bike.id);
    },
  },
};
</script>
