<template lang="pug">
  el-table(
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='tickets'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line {{ scope.$index + 1}}
    el-table-column(
      prop='status'
      :label="$t('common.status')"

    )
      template(slot-scope='scope')
        .status-wrapper.d-flex.align-items-center(v-if="inBetween('ticketsStatuses', scope.row.status, 41, 60)")
          span.oval.cancelled
          span {{ ticketsStatuses[scope.row.status].message }}
        .status-wrapper.d-flex.align-items-center(v-if="inBetween('ticketsStatuses', scope.row.status, 1, 20)")
          span.oval.pending
          span {{ ticketsStatuses[scope.row.status].message }}
        .status-wrapper.d-flex.align-items-center(v-if="inBetween('ticketsStatuses', scope.row.status, 21, 40)")
          span.oval.completed
          span {{ ticketsStatuses[scope.row.status].message }}
    el-table-column(
      v-if="selectedStatus === 'in_progress' || selectedStatus === 'resolved'"
      prop='operator'
      :label="$t('label.assigned_operator')"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer(
          v-if="scope.row.operator"
          @click="goToWIthId('users', scope.row.operator.id)"
        ) {{ scope.row.operator.first_name + " " + scope.row.operator.last_name }}
        span(v-else) -
    el-table-column(
      prop='ticket_type_object.label'
      :label="$t('label.type')"
    )
      template(slot-scope='scope')
        span {{ scope.row.ticket_type_object.label }}
    el-table-column(
      prop='created_at'
      :label="$t('common.time_of_report')"
    )
      template(slot-scope='scope')
        span {{ computeDateFormat(scope.row.created_at) }}
    el-table-column(
      prop='user'
      :label="$t('common.customer_name')"
    )
      template(slot-scope='scope')
        span.text-underline.cursor-pointer(
          v-if="scope.row.user"
          @click="goToWIthId('customers', scope.row.user.id)"
        ) {{ scope.row.user.first_name + " " + scope.row.user.last_name }}
        span(v-else) -
    el-table-column(
      prop='description'
      :label="$t('label.description')"
    )
      template(slot-scope='scope')
        el-tooltip.item(
          v-if="scope.row.description && scope.row.description.length > MAX_FIELD_LENGTH"
          effect="dark"
          :content="scope.row.description"
          placement="bottom-end"
        )
          span {{ trimTableField(scope.row.description) }}
        span(v-if="scope.row.description && scope.row.description.length <= MAX_FIELD_LENGTH") {{ scope.row.description }}
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
      width="360px"
    )
      template(slot-scope='scope')
        .d-flex(:key="actionsButtonKey")
          img.cursor-pointer.mr-2(
            v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.TICKET, ['show'])"
            src="@assets/preview.svg"
            @click="handleViewTicketDetails(scope.row)"
          )
          el-button.custom-table-btn.mr-2(
            v-loading="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn')"
            :disabled="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn') || isActionButtonsLoading(scope.row, 'loadingSolveTicketBtn')"
            v-if="computeStartWorkButtonAction(scope.row) && showItemDynamically(user, dynamicViewsConstants.resource_names.TICKET, ['update'])"
            @click="startWork(scope.row)"
          ) {{ $t('tickets.start_work') }}
          el-button.custom-table-btn-dark.mr-2(
            v-loading="isActionButtonsLoading(scope.row, 'loadingSolveTicketBtn')"
            :disabled="isActionButtonsLoading(scope.row, 'loadingStartWorkBtn') || isActionButtonsLoading(scope.row, 'loadingSolveTicketBtn')"
            v-if="computeSolveButtonAction(scope.row) && showItemDynamically(user, dynamicViewsConstants.resource_names.TICKET, ['update'])"
            @click="solveTicket(scope.row)"
          ) {{ $t('tickets.solve') }}
</template>

<script>
import { filterUtils, inBetween, MAX_FIELD_LENGTH, trimTableField } from "@utils/filterUtils";
import { ticketsReports, ticketsStatuses } from "@utils/statuses";
import { routerUtils } from "@utils/router";
import { mapActions, mapMutations, mapState } from "vuex";
import { dynamicViewsConstants } from "@src/constants";
import { general } from "@src/services/dynamicViews/general";

export default {
  name: "TicketsViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    tickets: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  watch: {
    tickets() {
      this.tickets.forEach((ticket, index) => {
        this.loadingStartWorkBtn[index] = {
          isLoading: false,
          id: ticket.id
        }

        this.loadingSolveTicketBtn[index] = {
          isLoading: false,
          id: ticket.id
        }
      })
    }
  },

  data() {
    return {
      actionsButtonKey: 0,
      loadingStartWorkBtn: [],
      loadingSolveTicketBtn: [],

      MAX_FIELD_LENGTH,
      ticketsStatuses,
      ticketsReports,
      inBetween,
      trimTableField,
      dynamicViewsConstants
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    ...mapActions("ticket", ['updateTicketStatus']),

    ...mapMutations("dialog", ["showViewTicketDetailsDialog"]),

    ...mapMutations("ticket", ["setTicket", 'setShouldFetchTickets']),

    computeStartWorkButtonAction(ticket) {
      return this.selectedStatus === 'unresolved' || ticket.status === 'unresolved'
    },

    computeSolveButtonAction(ticket) {
      return (this.selectedStatus === 'in_progress' || this.selectedStatus === 'unresolved') ||
          (ticket.status === 'unresolved' || ticket.status === 'in_progress')
    },

    isActionButtonsLoading(ticket, btnType) {
      let indexOfBtn = this._data[btnType].findIndex(btn => btn.id === ticket.id)
      return this._data[btnType][indexOfBtn].isLoading
    },

    handleViewTicketDetails(ticket) {
      this.setTicket(ticket);
      this.showViewTicketDetailsDialog();
    },

    async startWork(ticket) {
      let indexOfBtn = this.loadingStartWorkBtn.findIndex(btn => btn.id === ticket.id)
      this.loadingStartWorkBtn[indexOfBtn].isLoading = true
      this.actionsButtonKey += 1

      let params = { ...ticket, status: 'in_progress' }
      this.updateTicketStatus(params)
          .then(() => {
            this.setShouldFetchTickets(true)
          })
          .finally(() => {
            this.loadingStartWorkBtn[indexOfBtn].isLoading = false
            this.actionsButtonKey += 1
          })
    },

    solveTicket(ticket) {
      let indexOfBtn = this.loadingSolveTicketBtn.findIndex(btn => btn.id === ticket.id)
      this.loadingSolveTicketBtn[indexOfBtn].isLoading = true
      this.actionsButtonKey += 1

      let params = { ...ticket, status: 'resolved' }
      this.updateTicketStatus(params)
          .then(() => {
            this.setShouldFetchTickets(true)
          })
          .finally(() => {
            this.loadingSolveTicketBtn[indexOfBtn].isLoading = false
            this.actionsButtonKey += 1
          })
    },
  }
}
</script>
