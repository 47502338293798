<template lang="pug">
  el-table(
    style='width: 100%;'
    header-cell-class-name='table-header'
    cell-class-name='pl-4'
    :data='rides'
  )
    el-table-column(
      width="55"
    )
      template(slot-scope='scope')
        span.pre-line(
          @click="adminResource() ? copyToClipBoard(scope.row.id) : ''"
          :class="adminResource() ? ['text-underline', 'cursor-pointer'] : '' "
          :title="adminResource() ? 'Copiază id cursă' : ''") {{ scope.$index + 1}}
    //- el-table-column(
    //-   v-if="this.adminResource()"
    //-   :label="$t('label.id')"
    //-   width="85px"
    //- )
    //-   template(slot-scope='scope')
    //-     span.pre-line {{ scope.row.id }}
    el-table-column(
      prop='start_at'
      :label="$t('label.starting_date')"
    )
      template(slot-scope='scope')
        span.pre-line(v-if="scope.row.start_at") {{ computeDateFormat(scope.row.start_at) }}
        span.pre-line(v-else) {{ computeDateFormat(scope.row.created_at) + " *" }}
    el-table-column.pre-line(
      prop='start_docking_station_name'
      :label="$t('label.start_dock_end_dock')"
    )
      template(slot-scope='scope')
        .docks.pre-line
          span {{ scope.row.start_docking_station_name + "/ \n" }}
          span(v-if="!scope.row.start_docking_station_name") -

          span {{ scope.row.end_docking_station_name }}
          span(v-if="!scope.row.end_docking_station_name") -
    el-table-column(
      prop='duration_seconds'
      :label="$t('common.bike_serial_number')"
    )
      template(slot-scope='scope') {{ scope.row.bike_serial_number }}
    el-table-column(
      prop='duration_seconds'
      :label="$t('common.ride_duration')"
      width="110"
    )
      template(slot-scope='scope') {{ computeRideDuration(scope.row.duration_seconds) }}
    el-table-column(
      prop='price'
      :label="$t('common.ride_price')"
      width="115"
    )
      template(slot-scope='scope')
        span.pre-line {{ $n(scope.row.price, 'currency') + "/ \n" + $n(scope.row.already_paid_ammount, 'currency') }}
    el-table-column(
      prop='customer_email'
      :label="$t('rides.customer')"
    )
      template(slot-scope='scope')
        span.cursor-pointer.text-underline(@click="goToWIthId('customers', scope.row.customer_id)") {{ scope.row.customer_email }}
    el-table-column(
      prop='status_object.label'
      :label="$t('common.ride_status')"
    )
      template(slot-scope='scope')
        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 21, 40)")
          span.oval.completed
          span {{ scope.row.status_object.label }}
        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 41, 60)")
          span.oval.cancelled
          span {{ scope.row.status_object.label }}
        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 1, 20)")
          span.oval.pending
          span {{ scope.row.status_object.label }}
        .status-wrapper(v-if="inBetween('ridesStatuses', scope.row.status, 60, 100)")
          span.oval.cancelled
          span {{ scope.row.status_object.label }}
    //- el-table-column(
    //-   prop='flag'
    //-   :label="$t('common.flagged')"
    //- )
    //-   template(slot-scope='scope')
    //-     span.pre-line.break-word(v-if="scope.row.flag !== 'no_problems'") 🚩 {{ scope.row.flag }}
    //-     span(v-else) --
    el-table-column(
      prop='actions'
      :label="$t('label.actions')"
    )
      template(slot-scope='scope')
        img.cursor-pointer.ml-2(
          v-if="showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['show'])"
          src="@assets/preview.svg"
          @click="handleViewRideDetails(scope.row)"
        )
        img.cursor-pointer.ml-2(
          v-if=`
            selectedStatus !== 'completed' &&
            inBetween('ridesStatuses', scope.row.status, 2, 20) &&
            showItemDynamically(user, dynamicViewsConstants.resource_names.RIDE, ['force_stop'])
          `
          src="@assets/rides/restrict.svg"
          @click="handleStopRide(scope.row)"
        )
</template>

<script>
import { filterUtils, inBetween } from "@utils/filterUtils";
import { routerUtils } from "@utils/router";
import { roles } from "@utils/roles";
import store from "@state/store";
import { flaggedRidesStatuses, ridesStatuses } from "@utils/statuses";
import { mapMutations, mapState } from "vuex";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";

export default {
  name: "RidesViewTable",
  mixins: [filterUtils, routerUtils, general],

  props: {
    rides: {
      type: Array
    },

    selectedStatus: {
      type: String,
    }
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  data() {
    return {
      ridesStatuses,
      flaggedRidesStatuses,
      inBetween,
      dynamicViewsConstants
    }
  },

  methods: {
    ...mapMutations("dialog", ["showViewRideDetailsDialog", "showForceStopRideDialog"]),

    ...mapMutations("ride", ["setRide"]),

    async handleStopRide(ride) {
      this.setRide(ride);
      this.showForceStopRideDialog()
    },

    handleViewRideDetails(ride) {
      this.showViewRideDetailsDialog();
      this.setRide(ride);
    },

    adminResource() {
      return (
          this.user &&
          [roles.SUPER_ADMINISTRATOR, roles.ADMINISTRATOR].some(r => store.state.auth.user.roles.map(role => role.name).indexOf(r) >= 0)
      );
    },

    copyToClipBoard(textToCopy) {
      const tmpTextField = document.createElement("textarea")
      tmpTextField.textContent = textToCopy
      tmpTextField.setAttribute("style","position:absolute; right:200%;")
      document.body.appendChild(tmpTextField)
      tmpTextField.select()
      tmpTextField.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand("copy")
      tmpTextField.remove();

      this.$message({
        type: "success",
        message: "Id cursă copiat",
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.status-wrapper {
  display: flex;
  align-items: center;

  & > span {
    margin-left: 22px;
  }

  .status {
    display: flex;
    align-items: center;
  }

  span {
    display: block;
  }
}
</style>
