<template lang="pug">
  .view-bike-details
    .bike-modal-table
      el-row(:gutter="50" style="margin: auto; text-align:center;")
        el-tabs.mt-4(
          v-model='activeName'
        )
          el-tab-pane(
            :label="$t('label.bike_details')"
            name='bike_details'
          )
          el-tab-pane(
            :label="$t('label.bike_history')"
            name='bike_history'
          )
          el-tab-pane(
            :label="$t('common.maintenance_history')"
            name='maintenance_history'
          )
          //- el-tab-pane(
          //-   :label="$t('label.bike_statistics')"
          //-   name='bike_statistics'
          //- )
      Details(v-if="activeName === 'bike_details'" :activeName="activeName")
      BikeHistory(
        v-if="activeName === 'bike_history'  && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_rides'])"
        :activeName="activeName"
      )
      BikeMaintenancesHistory(
        v-if="activeName === 'maintenance_history' && showItemDynamically(user, dynamicViewsConstants.resource_names.BIKE, ['show_history_maintenances'])" :activeName="activeName"
      )
      //- Statistics(v-if="activeName === 'bike_statistics'" :activeName="activeName")
</template>

<script>
import Details from "@components/bikes/viewBikeDetails/Details";
import BikeMaintenancesHistory from "@components/bikes/_shared/BikeMaintenancesHistory";
import BikeHistory from "@components/bikes/viewBikeDetails/BikeHistory";
import Statistics from "@components/bikes/viewBikeDetails/Statistics";
import { general } from "@src/services/dynamicViews/general";
import { dynamicViewsConstants } from "@src/constants";
import { mapState } from "vuex";

export default {
  name: "ViewBikeDetails",
  mixins: [general],
  components: { BikeMaintenancesHistory, BikeHistory, Details, Statistics },

  data() {
    return {
      activeName: "bike_details",
      dynamicViewsConstants
    };
  },


  computed: {
    ...mapState("auth", ["user"]),
        ...mapState("bike", ["bike"]),
  },

  watch: {
    bike() {
      this.activeName = "bike_details";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@variables";

.view-bike-details {
  .load-more-btn {
    color: $primary;
  }

  .begin-maintenance-btn {
    line-height: 22px;
    width: fit-content;
    padding: 0 9px !important;
    border-radius: 7px !important;
  }
}
</style>
