<template lang="pug">
  .view-bike-maintenance-history
    .bike-statistics
      el-row.center.details-bold(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 15px;"
      )
        el-col(:span="4")
          span {{ $t('bikes.statistics.history_maintenance') }}
        el-col(:span="7")
          span {{ $t('bikes.statistics.history_maintenance_partial_non_rideable') }}
        el-col(:span="7")
          span {{ $t('bikes.statistics.history_maintenance_partial_rideable') }}
      el-row.center(
        :gutter="50"
        type="flex"
        justify="center"
        style="margin-top: 10px; margin-bottom: 25px;font-size:20px"
      )
        el-col(:span="4")
          span.break-word {{ statistics.no_of_maintenances }}
        el-col(:span="7")
          span.break-word {{ statistics.no_of_maintenances_partial_non_rideable }}
        el-col(:span="7")
          span.break-word {{ statistics.no_of_maintenances_partial_rideable }}
    .bike-maintenance-history
      el-table.bike_maintenance_table(
        :data="maintenance_history"
        v-loading="loading"
        :gutter="0"
        center
        height="250"
        style="width: 100%;"
      )
        el-table-column(
          width="55"
        )
          template(slot-scope='scope')
            span.pre-line {{ scope.$index + 1}}
        el-table-column(
          width="180"
          prop="created_at"
          :label="$t('label.date')"
        )
          template(slot-scope='scope')
            span {{ computeDateFormat(scope.row.created_at) }}
        el-table-column(
          width="250"
          prop="technician"
          :label="$t('label.technician')"
        )
          template(slot-scope='scope')
            span(v-if="scope.row.technician") {{ scope.row.technician.first_name + " " + scope.row.technician.last_name + " " + scope.row.technician.email }}
            span(v-else) -
        el-table-column(
          width="180"
          prop="status"
          :label="$t('label.maintenance_status')"
        )
          template(slot-scope='scope')
            .status-wrapper.d-flex.align-items-center(v-if="inBetween('bikesMaintenanceStatuses', scope.row.status, 1, 20)")
              span.oval.completed
              span {{ bikesMaintenanceStatuses[scope.row.status].message }}
            .status-wrapper.d-flex.align-items-center(v-if="inBetween('bikesMaintenanceStatuses', scope.row.status, 21, 40)")
              span.oval.pending
              span {{ bikesMaintenanceStatuses[scope.row.status].message }}
            .status-wrapper.d-flex.align-items-center(v-if="inBetween('bikesMaintenanceStatuses', scope.row.status, 41, 60)")
              span.oval.unavailable
              span {{ bikesMaintenanceStatuses[scope.row.status].message }}
        el-table-column(
          prop="description"
          :label="$t('label.description')"
        )
          template(slot-scope='scope')
            span.pre-line {{ scope.row.description }}
      DialogTablePagination(
        :activeName="activeName"
        @viewData="handleGetData"
        @handleTableLoading="handleLoading"
      )
</template>

<script>
import { bikesMaintenanceStatuses, bikesStatuses } from "@utils/statuses";
import { filterUtils, inBetween } from "@utils/filterUtils";
import { mapState, mapActions } from "vuex";
import DialogTablePagination from "@components/_shared/DialogTablePagination";

export default {
  name: "BikeMaintenancesHistory",
  mixins: [filterUtils],
  components: { DialogTablePagination },

  props: {
    activeName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      maintenance_history: [],
      statistics: {
        no_of_maintenances: 0,
        no_of_maintenances_partial_non_rideable: 0,
        no_of_maintenances_partial_rideable: 0
      },

      loading: false,
      bikesStatuses,
      bikesMaintenanceStatuses,
      inBetween,
    };
  },

  computed: {
    ...mapState("bike", ["bike"]),
  },

  async created() {
    await this.handleGetCustomersStatistics();
  },

  methods: {
    ...mapActions("bike", ["getBikeStatistics"]),

    async handleGetCustomersStatistics() {
      this.statistics = await this.getBikeStatistics(this.bike.id);
    },

    handleGetData(data) {
      this.maintenance_history = data;
    },

    handleLoading(loading) {
      this.loading = loading;
    },
  },
};
</script>

<style lang="scss">
.status-wrapper {
  .status {
    margin-left: 5px;
  }
}
</style>
